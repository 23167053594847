import React from "react";
import Header from "./Header";
import Offers from "../../components/offers/Offers";
import { Column, Row } from "../../Layouts/container";
import { group_img, shipment_img } from "../../utilities/assets";
import TrendingProducts from "./TrendingProducts";
import style from "./Home.module.css";
const HomePage = () => {
  return (
    <>
      <Header />
      {/* offers */}
      <Row>
        <Offers
          label="promo offer"
          title="Get 10 20mg for 55 euro"
          bgSettings={{
            bgColor: "#770599",
            color: "#fff",
            image: group_img,
          }}
        />
        <Offers
          label="door shipment for"
          title="Purchase exceeding 20 euro"
          bgSettings={{
            bgColor: "#a13b0e",
            color: "white",
            image: shipment_img,
          }}
        />
      </Row>
      {/* trends */}
      <Column>
        <h2 className={style.heading}>Trending</h2>
        <TrendingProducts />
      </Column>

      {/* trends */}
      <Column>
        <h2 className={style.heading}>New Arrivals</h2>
        <TrendingProducts />
      </Column>

      {/* promotion */}
      <Row>
        <Offers
          label="promo offer"
          title="Get 10% off your first pruchase with the promo code #Free"
          bgSettings={{
            bgColor: "#1131ee",
            color: "#fff",
            image: group_img,
          }}
        />
      </Row>
    </>
  );
};

export default HomePage;
