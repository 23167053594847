import React from "react";
import { landing_img } from "../../utilities/assets";
import style from "./Header.module.css";

const Header = () => {
  return (
    <section className={style.header}>
      <img
        src={landing_img}
        alt="group of lost mary photos"
        className={style.img}
      />
    </section>
  );
};
export default Header;
