import React from "react";
import style from "./Search.module.css";

const Search = () => {
  return (
    <div className={style.search}>
      <input
        type="text"
        placeholder="Search for product"
        className={style.searchField}
      />
      <button className={style.go}>SEARCH</button>
    </div>
  );
};

export default Search;
