import React from "react";

import { useDispatch } from "react-redux";
import { toggleModal } from "../../store/features/ModalSlice";

const CartViewModalWrapper = ({ children }) => {
  const dispatcher = useDispatch();
  return (
    <span onClick={() => dispatcher(toggleModal({ type: "cart_view" }))}>
      {children}
    </span>
  );
};

export default CartViewModalWrapper;
