import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logo_white_img } from "../../utilities/assets";
import style from "./Menu.module.css";
import { HiOutlineShoppingBag, HiOutlineUser } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import CartViewModalWrapper from "../cart_widgets/cart_view_wrapper";
import { toggleModal } from "../../store/features/ModalSlice";

const Menu = () => {
  const dispatcher = useDispatch();
  const [navState, setNavState] = useState({ flag: false, style: "hide" });

  const navDrawerHandler = () => {
    setNavState((prev) => {
      return { flag: !prev.flag, style: prev.flag ? "hide" : "show" };
    });
  };

  return (
    <nav className={style.nav}>
      <img src={logo_white_img} alt="logo" className={style.logo} />
      <span
        className={`${style.closeNav} ${style[navState.style]}`}
        onClick={navDrawerHandler}
      >
        <AiOutlineCloseCircle />
      </span>
      <ul className={`${style.menuLabel} ${style[navState.style]}`}>
        <li>
          <Link to="/" className={style.active}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/shop">Shop</Link>
        </li>
        <li>
          <Link to="/about">About Us</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
        {/* <li className={style.mobileOnly}>
          <Link to="/profile">Profile</Link>
        </li> */}
      </ul>
      <ul className={style.menuActionIcons}>
        <li className={style.navDrawer} onClick={navDrawerHandler}>
          <FaBars />
        </li>
        <li
          className={style.pointer}
          onClick={() => dispatcher(toggleModal({ type: "search_modal" }))}
        >
          <BsSearch />
        </li>
        {/* <li className={style.lg}>
          <Link to="/profile">
            <HiOutlineUser />
          </Link>
        </li> */}
        <li className={style.cartBag}>
          <CartViewModalWrapper>
            <HiOutlineShoppingBag />
            <span className={style.cartCounter}> 0</span>
          </CartViewModalWrapper>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
