import React from "react";
import style from "./Price.module.css";
import { formatedCurrency } from "../../utilities/functions";

const Price = ({ discount = 0, originalPrice = 0, currentPrice = 0 }) => {
  return (
    <p className={style.amount}>
      {discount ? <span>{formatedCurrency(originalPrice)}</span> : ""}{" "}
      {formatedCurrency(currentPrice)}
    </p>
  );
};

export default Price;
