import React from "react";
import style from "./Contact.module.css";
import { Column } from "../../Layouts/container";
import BreadCrumbs from "../../components/breadcrumbs";
import { BsSend } from "react-icons/bs";

const Contact = () => {
  return (
    <>
      <Column>
        <BreadCrumbs
          links={[
            { label: "Home", path: "/" },
            { label: "Contact Us", path: "#" },
          ]}
        />
        <div className={style.root}>
          <div className={style.formGroup}>
            <label>Name</label>
            <input type="text" placeholder="Name" />
          </div>
          <div className={style.formGroup}>
            <label>Email Address</label>
            <input type="text" placeholder="Email Address" />
          </div>
          <div className={style.formGroup}>
            <label>Contact Number</label>
            <input type="text" placeholder="Contact Number" />
          </div>
          <div className={style.formGroup}>
            <label>Message</label>
            <textarea placeholder="message"></textarea>
          </div>
          <button>
            SEND MESSAGE &nbsp;
            <BsSend />
          </button>
        </div>
      </Column>
    </>
  );
};

export default Contact;
