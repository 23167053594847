import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    openProductDetailsModal: false,
    openCartViewModal: false,
    openSearchModal: false,
  },
  reducers: {
    toggleModal: (state, { payload }) => {
      if (payload.type === "product_details")
        return {
          ...state,
          openProductDetailsModal: !state.openProductDetailsModal,
        };

      if (payload.type === "cart_view")
        return { ...state, openCartViewModal: !state.openCartViewModal };

      if (payload.type === "show_cart_view")
        return { ...state, openCartViewModal: true };

      if (payload.type === "search_modal")
        return { ...state, openSearchModal: !state.openSearchModal };
    },
  },
});

const { reducer, actions } = modalSlice;

export const { toggleModal } = actions;
export default reducer;
