import React from "react";
import style from "./About.module.css";
import BreadCrumbs from "../../components/breadcrumbs";
import { Column } from "../../Layouts/container";

const About = () => {
  return (
    <>
      <Column>
        <BreadCrumbs
          links={[
            { label: "Home", path: "/" },
            { label: "About Us", path: "#" },
          ]}
        />
        <div className={style.root}>
          <div className={style.content}>
            <p className={style.heading}>Why Us</p>
            <h3 className={style.title}>LEADING SUPPLIER OF E-CIGARETTE</h3>
            <p className={style.text}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              tincidunt mauris ut ipsum varius, at semper dui tempor. Aliquam in
              malesuada tellus, id efficitur ligula. Suspendisse cursus pharetra
              mi, vitae eleifend lectus ultricies a. Curabitur ultrices tortor
              vel dui blandit, ut euismod justo eleifend. Vestibulum tincidunt
              commodo quam, a venenatis ipsum mattis ac. Sed at varius justo.
              Proin ac suscipit lectus, ac eleifend sem.
            </p>
            <div>brand img</div>
          </div>
          <div className={style.img}>
            <img src="/products_assets/about.webp" />
          </div>
        </div>
      </Column>
    </>
  );
};

export default About;
