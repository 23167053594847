import React from "react";
import { RowWrap } from "../../Layouts/container";
import Product from "../../components/product/Product";

const Products = () => {
  return (
    <RowWrap>
      {[
        {
          image: "blue_razz_ice.jpg",
          title: "Blue Razz Ice ",
          amount: 30.5,
          discount: 2,
        },
        {
          image: "cherry_peach_Lemoande.webp",
          title: "Cherry Peach Lemoande ",
          amount: 30,
          discount: 0,
        },
        {
          image: "blue_razz_ice.jpg",
          title: "Blue Razz Ice ",
          amount: 30.5,
          discount: 0,
        },
        {
          image: "cherry_peach_Lemoande.webp",
          title: "Cherry Peach Lemoande ",
          amount: 30,
          discount: 5,
        },
      ].map((item, index) => (
        <Product data={item} key={index} />
      ))}
    </RowWrap>
  );
};
export default Products;
