import { configureStore, combineReducers } from "@reduxjs/toolkit";
import CartReducer from "./features/CartSlice";
import ModalReducer from "./features/ModalSlice";

// Redux persist
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist/";
const persistConfig = { key: "root", storage };
const reducers = combineReducers({ cart: CartReducer, modal: ModalReducer });
const persistedReducers = persistReducer(persistConfig, reducers);
// End of Redux persist

const store = configureStore({ reducer: persistedReducers });

export default store;
