import React from "react";
import style from "./BreadCrumbs.module.css";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ links }) => {
  return (
    <ul className={style.breadcrumbs}>
      {/* <li>
        <Link to="/">Home</Link>
      </li>
      <li>Shop</li> */}
      {links.map((link) => {
        if (link.path !== "#")
          return (
            <li>
              <Link to={link.path}>{link.label}</Link>
            </li>
          );
        return <li>{link.label}</li>;
      })}
    </ul>
  );
};
export default BreadCrumbs;
