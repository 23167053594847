import React from "react";
import style from "./CartItem.module.css";
import { MdDeleteForever } from "react-icons/md";
import ProductCounter from "./ProductCounter";
import { formatedCurrency } from "../../utilities/functions";

const CartItem = () => {
  return (
    <div className={style.cartItem}>
      <button className={style.deleteBtn}>
        <MdDeleteForever />
      </button>
      <div className={style.row}>
        <div>
          <img
            src="/products_assets/blue_razz_ice.jpg"
            className={style.img}
            alt="title here"
          />
        </div>
        <div className={style.content}>
          <div className={style.amount}>{formatedCurrency(0)}</div>
          <ProductCounter />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
