import React from "react";
import style from "./ProductDetails.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AddToCartBtn, PriceView, ProductCounterView } from "../cart_widgets";
import { useDispatch } from "react-redux";
import { toggleModal } from "../../store/features/ModalSlice";
import { APP_CONSTANTS } from "../../utilities/constants";

const ProductDetails = () => {
  const dispatcher = useDispatch();
  const [product_details_modal] = APP_CONSTANTS.modal;
  // handlers
  const closeModalHandler = () => {
    dispatcher(toggleModal({ type: product_details_modal }));
  };

  return (
    <div className={style.root}>
      <button className={style.btnClose} onClick={closeModalHandler}>
        <AiOutlineCloseCircle />
      </button>
      <div className={style.image}>
        <img
          src="/products_assets/blue_razz_ice.jpg"
          alt="use title"
          className={style.img}
        />
      </div>
      <div className={style.content}>
        <div className={style.header}>
          <p className={style.title}>Blue Razz Ice</p>
          <PriceView discount={2} originalPrice={30} currentPrice={29.5} />
        </div>
        <p className={style.description}>
          Lost Mary Blue Razz Ice features a fruity blend of berries with ice,
          on each inhale you will taste a combination of the delicious taste of
          blueberry with tart raspberry and cold menthol on exhale.
        </p>
        <div className={style.actionGroup}>
          <ProductCounterView />
          <AddToCartBtn />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
