import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleModal } from "../features/ModalSlice";
export const AddToCartThunk = createAsyncThunk(
  "cart/add_to_cart",
  (productData, { getState, dispatch }) => {
    const { modal } = getState();
    console.log(getState());
    // update cart state
    console.log(productData);
    dispatch(toggleModal({ type: "show_cart_view" }));
    modal.openProductDetailsModal &&
      dispatch(toggleModal({ type: "product_details" }));
  }
);
