import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import Menu from "./components/menu";
import Footer from "./Layouts/footer";
import { CartView } from "./components/cart_widgets";
import ProductDetails from "./components/product/ProductDetails";
import { useSelector } from "react-redux";
import Shop from "./pages/shop";
import Search from "./components/product/Search";
import About from "./pages/about";
import Contact from "./pages/contact";
import Checkout from "./pages/checkout";

export default function App() {
  const { openCartViewModal, openProductDetailsModal, openSearchModal } =
    useSelector((store) => store.modal);

  return (
    <Router>
      <Menu />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
      <Footer />
      {openCartViewModal && <CartView />}
      {openProductDetailsModal && <ProductDetails />}
      {openSearchModal && <Search />}
    </Router>
  );
}
