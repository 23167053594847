import React from "react";
import style from "./Footer.module.css";
import { Link } from "react-router-dom";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { RiLinksLine } from "react-icons/ri";
const Footer = () => {
  return (
    <footer className={style.footerBottom}>
      <div className={style.row}>
        <div className={`${style.securePayment} ${style.item}`}>
          <div className={style.icon}>
            <AiOutlineSafetyCertificate />
          </div>
          <p>Secure payment</p>
        </div>
        <div className={style.item}>
          <h3 className={style.mb3}>Contact</h3>
          <p>+353 899 516 678</p>
          <p>info@email.com</p>
        </div>
        <div className={style.item}>
          <h3 className={style.mb3}>Links</h3>
          <Link to="" className={style.link}>
            <RiLinksLine /> &nbsp; Terms & Conditions
          </Link>
          <Link to="" className={style.link}>
            <RiLinksLine /> &nbsp; Return policy
          </Link>
        </div>
      </div>
      <div className={style.center}>&copy; 2023 </div>
    </footer>
  );
};

export default Footer;
