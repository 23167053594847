import React from "react";
import style from "./Container.module.css";

export const Row = ({ color, bgImg, children }) => {
  return <div className={style.row}>{children}</div>;
};

export const RowWrap = ({ color, bgImg, children }) => {
  return <div className={style.rowWrap}>{children}</div>;
};

export const Column = ({ color, bgImg, children }) => {
  return <div className={style.column}>{children}</div>;
};
