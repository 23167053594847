import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: { isModalOpen: false, quantity: 0 },
  reducers: {
    increment: (state, action) => console.log(action),
    decrement: (state, action) => console.log(action),
  },
});

const { reducer, actions } = cartSlice;

export const { increment, decrement } = actions;
export default reducer;
