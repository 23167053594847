// import React from "react";
// import style from "./Offers.module.css";
// import { Link } from "react-router-dom";

// const Offers = ({ bgSettings, label, title }) => {
//   const { bgColor, color, image } = bgSettings || {};
//   return (
//     <article className={style.root} style={{ backgroundColor: bgColor, color }}>
//       <div className={style.content}>
//         <p>{label}</p>
//         <h2>{title}</h2>
//         <Link to="/shop" className={style.btn}>
//           Shop Now
//         </Link>
//       </div>
//       <div className={style.image}>
//         <img src={image} alt={title} />
//       </div>
//     </article>
//   );
// };
// export default Offers;

import React from "react";
import style from "./Offers.module.css";
import { Link } from "react-router-dom";

const Offers = ({ bgSettings, label, title }) => {
  const { bgColor, color, image } = bgSettings || {};
  return (
    <article className={style.root} style={{ backgroundColor: bgColor, color }}>
      <div className={style.content}>
        <p>{label}</p>
        <h2>{title}</h2>
        <Link to="/shop" className={style.btn}>
          Shop Now
        </Link>
      </div>
      <div className={style.image}>
        <img src={image} alt={title} />
      </div>
    </article>
  );
};
export default Offers;
