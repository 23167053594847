import React from "react";
import style from "./Cart.module.css";
import { IoIosClose } from "react-icons/io";
import { SlBag } from "react-icons/sl";
import { formatedCurrency } from "../../utilities/functions";
import CartItem from "./CartItem";
import CartViewModalWrapper from "./cart_view_wrapper";
import { Link } from "react-router-dom";

const Cart = () => {
  return (
    <div className={style.cart}>
      <div className={style.top}>
        <CartViewModalWrapper>
          <button className={style.btnClose}>
            <IoIosClose />
          </button>
        </CartViewModalWrapper>
        <div className={style.label}>
          <span className={style.icon}>
            <SlBag />
          </span>
          Cart
        </div>
      </div>
      <div className={style.content}>
        {[1, 2, 3].map((item, index) => (
          <CartItem key={index} />
        ))}
      </div>
      <div className={style.bottom}>
        <div className={style.row}>
          <p>Sub Total</p>
          <p className={style.price}>{formatedCurrency(0)}</p>
        </div>
        <div className={style.checkout}>
          <Link className={style.checkoutBtn} to="/checkout">
            Checkout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
