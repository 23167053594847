import React from "react";
import { useDispatch } from "react-redux";
import style from "./Product.module.css";
import { formatedCurrency } from "../../utilities/functions";
import { AddToCartBtn, PriceView } from "../cart_widgets";
// import { fetchModalData } from "../../store/thunks/fetch_modal_data";
import { toggleModal } from "../../store/features/ModalSlice";
import { APP_CONSTANTS } from "../../utilities/constants";

const Product = ({ data }) => {
  const dispatcher = useDispatch();
  const { image, title, amount, discount } = data;
  const srcImg = "/products_assets/" + image;

  const currentPrice = formatedCurrency(amount - amount * discount * 0.01);

  const [product_details_modal] = APP_CONSTANTS.modal;

  // handlers
  const handleProductDetails = () => {
    console.log("details clicked");
    dispatcher(toggleModal({ type: product_details_modal }));
    // dispatcher(fetchModalData())
    //   .then((data) => console.log(data))
    //   .catch((error) => console.log(error));
  };

  return (
    <div className={style.product}>
      {discount ? (
        <div className={style.saleLabel}>ON SALE</div>
      ) : (
        <div className={style.saleLabelEmpty}></div>
      )}
      <div className={style.section} onClick={handleProductDetails}>
        <img src={srcImg} alt={title} className={style.img} />
        <p className={style.title}>{title}</p>
        <PriceView
          discount={discount}
          originalPrice={amount}
          currentPrice={currentPrice}
        />
      </div>
      <div className={style.section}>
        <AddToCartBtn />
      </div>
    </div>
  );
};

export default Product;
