import React from "react";
import style from "./AddToCart.module.css";
import { useDispatch } from "react-redux";
import { AddToCartThunk } from "../../store/thunks/add_to_cart";

const AddToCart = () => {
  const dispatcher = useDispatch();
  const addToCartHandler = () => {
    // Using thunk to trigger multiple dispatch
    dispatcher(
      AddToCartThunk({ product_id: "10389", product_name: "Blue Ice Flavour" })
    );
  };
  return (
    <button className={style.btn} onClick={addToCartHandler}>
      ADD TO CART
    </button>
  );
};

export default AddToCart;
