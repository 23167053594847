import React from "react";
import style from "./Shop.module.css";
import { Column } from "../../Layouts/container";
import Products from "./Products";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../components/breadcrumbs";

const Shop = () => {
  return (
    <>
      <Column>
        <BreadCrumbs
          links={[
            { label: "Home", path: "/" },
            { label: "Shop", path: "#" },
          ]}
        />
        <Products />
      </Column>
    </>
  );
};
export default Shop;
