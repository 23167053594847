import React from "react";
import style from "./ProductCounter.module.css";
import { BiMinus, BiPlus } from "react-icons/bi";

const ProductCounter = () => {
  return (
    <div className={style.action}>
      <button className={style.btnAction}>
        <BiMinus />
      </button>
      <input type="text" className={style.counter} defaultValue={0} />
      <button className={style.btnAction}>
        <BiPlus />
      </button>
    </div>
  );
};

export default ProductCounter;
