import React from "react";
import style from "./Checkout.module.css";
import { Column, Row } from "../../Layouts/container";
import BreadCrumbs from "../../components/breadcrumbs";
import { formatedCurrency } from "../../utilities/functions";

const Checkout = () => {
  return (
    <>
      <Column>
        <BreadCrumbs
          links={[
            { label: "Home", path: "/" },
            { label: "Shop", path: "/shop" },
            { label: "Checkout", path: "#" },
          ]}
        />
        <div className={style.row}>
          <div>
            <h4 className={style.heading}>Billing Details</h4>
            <div className={style.formGroup}>
              <label>Fullname</label>
              <input type="text" placeholder="Fullname" />
            </div>
            <div className={style.formGroup}>
              <label>Address</label>
              <input type="text" placeholder="Address" />
            </div>
            <div className={style.formGroup}>
              <label>Eir-Code</label>
              <input type="text" placeholder="eirCode" />
            </div>
            <div className={style.formGroup}>
              <label>Email</label>
              <input type="text" placeholder="Email" />
            </div>
            <div className={style.formGroup}>
              <label>Phone Number</label>
              <input type="text" placeholder="Phone" />
            </div>
          </div>
          <div>
            <h4 className={style.heading}>Order Summary</h4>
            <ul className={style.orderSummary}>
              <li>
                <div>Product</div>
                <div>Subtotal</div>
              </li>
              {[1, 2, 3].map((item) => (
                <li>
                  <div>Product name</div>
                  <div>{formatedCurrency(0)}</div>
                </li>
              ))}
              <li>
                <div>Subtotal</div>
                <div>{formatedCurrency(0)}</div>
              </li>
              <li>
                <div>Total</div>
                <div>{formatedCurrency(0)}</div>
              </li>
              <li>
                <button>PLACE ORDER</button>
              </li>
            </ul>
          </div>
        </div>
      </Column>
    </>
  );
};

export default Checkout;
